import React from 'react';
import { Link } from 'react-router-dom';
import './coding.css'

function Coding() {
  return (
    <div className="code">
        <div className='code-home'>
            <div className="code">
                <h1>Coding, Robotics, and Fine Arts</h1>
                <div className="code-desc">
                    <p>These programs are available through partnerships with private and public schools. The <b>coding</b> curriculum focuses on teaching kids the fundamentals of computer programming, problem-solving, and software development. <b>Robotics</b> involves hands-on learning where students build and program robots, fostering skills in engineering, mathematics, and technology. 
                    <Link to="/"><button className='learn-more'>Back Home</button></Link></p>
                </div>
                <div className="code-right-desc">
                    <p><b>Fine Arts</b> programs encompass various artistic disciplines, such as visual arts, music, and theater, encouraging creative expression and cultural appreciation. These programs aim to provide students with diverse learning opportunities, combining technical skills with creative exploration.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Coding;
