import React from 'react';
import { Link } from 'react-router-dom';
import './awards.css';
import balletAwards from '../../images/ballet-awards.jpg';
import modernAwards from '../../images/modern-awards.jpg';
import gymnasticsAwards from '../../images/gymnastic-award.jpg';
import karateAwards from '../../images/karate-awards.jpg';
import fineartsAwards from '../../images/fine-arts-awards.jpg';

function Awards() {
  return (
    <div>
        <div className="awards" id="awards">
            <h1>Awards & Recognition</h1>

            {/* Introduction */}
            <div className="awards-intro">
                <p>
                At Hymnlets Dance & Art Academy, we celebrate the extraordinary achievements of our talented learners through awards and recognitions. Each year, our Annual Dance and Art Festival brings together students of all ages and backgrounds, allowing them to showcase their skills and hard work across multiple categories. These awards honor not only technical ability and creativity but also dedication, growth, and spirit.
                </p>
                <Link to="/"><button className='learn-more'>Back Home</button></Link>
            </div>

            {/* Ballet Award */}
            <div className="award-row">
                <div className="award-col"></div>
                <div className="award-col" id="ballet-award">
                    <h3>Ballet</h3>
                    <div className="award-desc">
                        <div className="awards-image">
                            <img src={balletAwards} alt="ballet awards" />
                        </div>

                        <p>Celebrating grace and precision, our Ballet awards honor outstanding technique and expression across skill levels.</p>
                    </div>
                </div>
            </div>

            {/* Modern Dance Award */}
            <div className="award-row">
                <div className="award-col" id="modern-dance-award">
                    <h3>Modern Dance</h3>
                    <div className="award-desc">
                        <div className="awards-image">
                            <img src={modernAwards} alt="Modern Dance Awards" />
                        </div>
                        <p>Awarding creativity and rhythm, the Modern Dance category shines a light on diverse dance styles, from jazz to hip-hop.</p>
                    </div>
                </div>
                <div className="award-col"></div>
            </div>

            {/* Gymnastics Award */}
            <div className="award-row">
                <div className="award-col"></div>
                <div className="award-col" id="gymnastics-award">
                <h3>Gymnastics</h3>
                    <div className="award-desc">
                        <div className="awards-image">
                            <img src={gymnasticsAwards} alt="awards" />
                        </div>
                        <p>Celebrating athleticism and artistry, our Gymnastics awards recognize remarkable skill, strength, and control.</p>
                    </div>
                </div>
            </div>

            {/* Martial Arts Award */}
            <div className="award-row">
                <div className="award-col" id="martial-arts-award">
                <h3>Karate & Taekwondo</h3>
                    <div className="award-desc">
                        <div className="awards-image">
                            <img src={karateAwards} alt="awards" />
                        </div>
                        <p>Awarding discipline and power, this category honors excellence in martial arts techniques and respect for the craft.</p>
                    </div>
                </div>
                <div className="award-col"></div>
            </div>

            {/* Fine Arts Award */}
            <div className="award-row">
                <div className="award-col"></div>
                <div className="award-col" id="fine-arts-award">
                    <h3>Fine Arts</h3>
                    <div className="award-desc">
                        <div className="awards-image">
                            <img src={fineartsAwards} alt="awards" />
                        </div>
                        <p>Recognizing visual talent, the Fine Arts awards celebrate creativity and skill in painting, drawing, and mixed media.</p>
                    </div>
                </div>
            </div>

          <Link to="/"><button className='learn-more'>Back Home</button></Link>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 240"><path fill="#FFC0CB" fill-opacity="1" d="M0,128L30,149.3C60,171,120,213,180,229.3C240,245,300,235,360,202.7C420,171,480,117,540,122.7C600,128,660,192,720,192C780,192,840,128,900,112C960,96,1020,128,1080,149.3C1140,171,1200,181,1260,176C1320,171,1380,149,1410,138.7L1440,128L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path></svg>
        </div>
    </div>
  )
}

export default Awards;