import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './gallery.css';

// Import images
import kendi from '../../images/kendiKena.jpg';
import gallery1 from '../../images/gallery.jpg'
import culture from '../../images/calture.jpg';
import jump from '../../images/jumping-girls.jpg';
import progBallet from '../../images/prog-ballet.jpg';
import progModern from '../../images/modern.jpg';
import modern2 from '../../images/modern2.jpg';
import modern3 from '../../images/modern3.jpg';
import festival from '../../images/festival.jpg';
import circus from '../../images/circus.jpeg';
import tassiaDance from '../../images/tassia-dance.jpg';
import dancingKids from '../../images/dance-moves.jpg';
import diversity from '../../images/diversity.jpg';
import kendiBallet from '../../images/kendi.jpg';
import tassiaGirls from '../../images/tassia-girls.jpg';
import theaterDance from '../../images/theater-dance.jpg';

const Gallery = () => {
  const [selectedMedia, setSelectedMedia] = useState(null);

  const media = [
    { src: kendi, type: 'image', alt: 'Kendi & Kena' },
    { src: gallery1, type: 'image', alt: 'Ballet kids' },
    { src: culture, type: 'image', alt: 'Cultural diversity' },
    { src: progBallet, type: 'image', alt: 'Progressive Ballet' },
    { src: progModern, type: 'image', alt: 'Progressive Modern' },
    { src: modern2, type: 'image', alt: 'Karate Kids' },
    { src: modern3, type: 'image', alt: 'Modern dance' },
    { src: festival, type: 'image', alt: 'Festival' },
    { src: circus, type: 'image', alt: 'Circus' },
    { src: tassiaDance, type: 'image', alt: 'Tassia school dance' },
    { src: dancingKids, type: 'image', alt: 'Dancing kids' },
    { src: diversity, type: 'image', alt: 'Exchange students' },
    { src: jump, type: 'image', alt: 'Progressive Ballet' },
    { src: kendiBallet, type: 'image', alt: 'Ballet girl' },
    { src: tassiaGirls, type: 'image', alt: 'Tassia girls ballet' },
    { src: theaterDance, type: 'image', alt: 'Festival' },
  ];

  const openMedia = (mediaItem) => {
    console.log("Opening media:", mediaItem); // Verify function call
    setSelectedMedia(mediaItem);
  };

  useEffect(() => {
    console.log("Current selectedMedia:", selectedMedia);
  }, [selectedMedia]);

  const closeMedia = () => {
    setSelectedMedia(null);
  };

  return (
    <>
      <Link to="/"><button className='learn-more'>Back Home</button></Link>
      <div className="gallery">
        {media.map((item, index) => (
          item.type === 'image' ? (
            <img
              key={index}
              src={item.src}
              alt={item.alt}
              onClick={() => openMedia(item)}
              className="gallery-image"
            />
          ) : (
            <video
              key={index}
              src={item.src}
              alt={item.alt}
              onClick={() => openMedia(item)}
              className="gallery-video"
              controls
            />
          )
        ))}

        {selectedMedia && (
          <div className="modal" onClick={(e) => e.target === e.currentTarget && closeMedia()}>
            {selectedMedia.type === 'image' ? (
              <img src={selectedMedia.src} alt={selectedMedia.alt} className="modal-image" />
            ) : (
              <video src={selectedMedia.src} alt={selectedMedia.alt} className="modal-image" controls />
            )}
            <button className="close-button" onClick={closeMedia}>X</button>
          </div>
        )}
        <Link to="/"><button className='learn-more'>Back Home</button></Link>
      </div>
    </>
  );
};

export default Gallery;
