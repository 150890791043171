import React, { useEffect} from "react";
import { useState } from 'react';
import { useTypewriter } from "react-simple-typewriter";
import { HashLink as Link } from "react-router-hash-link";
import CountUp from "react-countup";
import "./home.css";
import logo from "../../images/logo.png";
import bg from "../../images/ballet-vid.mp4";
import kendi from '../../images/kendiKena.jpg'
import unspoken from '../../images/unspoken.jpeg';
import trainers from '../../images/trainers.mp4';
import progBallet from '../../images/prog-ballet.jpg'
import progModern from '../../images/modern.jpg'
import karate from '../../images/karate-kids.png';
import art from '../../images/art.jpg';
import festival from '../../images/festival.jpg';
import circus from '../../images/circus.jpeg'
import { AuroraBackground } from "../../components/ui/aurora-background";
import { InfiniteMovingCards } from "../../components/ui/infinite-moving-cards";
import { PinContainer } from "../../components/ui/3d-pin";
import Headroom from "react-headroom";
import AOS from 'aos';


const items = [
  {
    // name: "Ballet Dance",
    content: (
      <div className="about-desc-col">
        <img src={progBallet} alt="Team building activity" />
        <h2>Ballet Dance</h2>
        <p>
        A favorite among children, it forms the core of the academy's offerings.
        </p>
        <Link to="/ballet"><button className='learn-more'>LEARN MORE</button></Link>
      </div>
    ),
  },
  {
    // name: "Modern Dance/Fusion",
    content: (
      <div className="about-desc-col">
        <img src={progModern} alt="Group" />
        <h2>Modern Dance/Fusion</h2>
        <p>
          Energetic and dynamic, this category appeals to kids who love high-energy movement.        </p>
          <Link to="/modern"><button className='learn-more'>LEARN MORE</button></Link>
      </div>
    ),
  },
  {
    // name: "Gymnastics",
    content: (
      <div className="about-desc-col">
        <img src={circus} alt="Team building activity" />
        <h2>Gymnastics & Circus</h2>
        <p>
          Offering both recreational and professional training through a partnership with a German-based company.
        </p>
        <Link to="/gymnastics"><button className='learn-more'>LEARN MORE</button></Link>
      </div>
    ),
  },
  {
    // name: "Karate",
    content: (
      <div className="about-desc-col">
        <img src={karate} alt="Karate" />
        <h2>Karate and Taekwondo</h2>
        <p>
          Martial arts training for discipline and physical development.
        </p>
        <Link to="/karate"><button className='learn-more'>LEARN MORE</button></Link>
      </div>
    ),
  },
  {
    // name: "Coding",
    content: (
      <div className="about-desc-col">
        <img src={art} alt="Team building activity" />
        <h2>Coding, Robotics, and Fine Arts</h2>
        <p>
          Available through partnerships with private and public schools.
        </p>
        <Link to="/coding-robotics-fine-arts"><button className='learn-more'>LEARN MORE</button></Link>
      </div>
    ),
  },
  {
    // name: "Dance",
    content: (
      <div className="about-desc-col">
        <img src={festival} alt="Team building activity" />
        <h2>Annual Hymnlets Dance Festival</h2>
        <p>
        The purpose of the event is to identify, develop, and nurture talent
        </p>
        <Link to="/festival"><button className='learn-more'>LEARN MORE</button></Link>
      </div>
    ),
  },
  {
    // name: "unspoken",
    content: (
      <div className="about-desc-col">
        <img src={unspoken} alt="Not alone" />
        <h2>The Unspoken- A Mental Wellness Program</h2>
        <p>
        It's designed to raise mental health awareness through the power of expressive dance.
        </p>
        <Link to="/the-unspoken"><button className='learn-more'>LEARN MORE</button></Link>
      </div>
    ),
  },
];

function Home() {
  const [ isVisible, setIsVisible ] = useState(false);
  const handleMenuVisibility = () => {
      setIsVisible(!isVisible);
  }
  const [typeEffect] = useTypewriter({
    words: ['Ballet', 'Dance fusion', 'Gymnastics', 'Martial Arts', 'Chess', 'Coding', 'Fine arts'],
    loop: {},
    typeSpeed: 100,
    deleteSpeed: 40,
    delaySpeed: 2000
  })
  useEffect(() => {
    AOS.init({duration: 2000});
  }, [])
  return (
    <div className="home" id="home">
      <div className="cover">

        {/* Header */}
        <Headroom>
          <div className="header">
            <div className="logo">
              <img src={logo} className="App-logo" alt="logo" />
            </div>

            {/* menu */}
            <div className="nav-bar">
              <Link to="#home">Home</Link>
              <Link to="#about">About</Link>
              <Link to="#dance">Dance Festival</Link>
              <Link to="#programs">Programs</Link>
              <Link to="#success">Success Stories</Link>
              <Link to="/diversity-and-inclusion">Diversity & Inclusion</Link>
              <Link to="/the-unspoken">The Unspoken</Link>
              <Link to="/awards">Awards</Link>
              <Link to="/gallery">Gallery</Link>
              <Link to="#contact">Contact</Link>
            </div>

            {/* mobile nav */}
            <div className= {isVisible ? 'nav-bar-mobile' : 'nav-bar-hide'}>
              <Link to="#home">Home</Link>
              <Link to="#about">About</Link>
              <Link to="#dance">Dance Festival</Link>
              <Link to="#programs">Programs</Link>
              <Link to="#success">Success Stories</Link>
              <Link to="/diversity-and-inclusion">Diversity & Inclusion</Link>
              <Link to="/the-unspoken">The Unspoken</Link>
              <Link to="/the-unspoken">Awards</Link>
              <Link to="/gallery">Gallery</Link>
              <Link to="#contact">Contact</Link>
            </div>

  <div className="menu" onClick={handleMenuVisibility}>
  <i class="bi bi-list"></i>
</div>

            <div className="socials">
              <a
                href="https://www.instagram.com/hymnletsdanceandartsacademy?igsh=YzljYTk1ODg3Zg=="
                target="_blank"
                rel="noreferrer"
              >
                <i class="bi bi-instagram"></i>
              </a>
              <a
                href="https://www.facebook.com/hymnletstalents"
                target="_blank"
                rel="noreferrer"
              >
                <i class="bi bi-facebook"></i>                
              </a>
            </div>
          </div>
        </Headroom>

        {/* Home */}
        <div className="home-desc">   
        <video autoPlay loop muted playsInline className="back-video w-full h-full object-cover">
          <source src={bg} type="video/mp4" />
        </video>
          {/* Carousel */}
          <div id="carouselExampleCaptions" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="3"
                aria-label="Slide 4"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="4"
                aria-label="Slide 5"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="5"
                aria-label="Slide 6"
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="slider">
                <div className="carousel-item active bottom-left" data-bs-interval="4000" >
                  <h5>BALLET</h5>
                  <p>A favorite among children, it forms the core of the academy's offerings.
                  </p>
                </div>
              </div>

              <div className="slider">
                <div className="carousel-item mid-right" data-bs-interval="4000" >
                  <h5>MODERN DANCE/FUSION</h5>
                  <p>
                  Energetic and dynamic, it appeals to kids who love high-energy movement.
                  </p>
                </div>
              </div>

              <div className="slider">
                <div className="carousel-item center" data-bs-interval="4000">
                  <h5>GYMNASTICS</h5>
                  <p>Offering both recreational and professional training through a partnership with a German-based company.</p>
                </div>
              </div>

              <div className="slider">
                <div className="carousel-item top-right" data-bs-interval="4000">
                  <h5>KARATE AND TAEKWONDO</h5>
                  <p>
                  Martial arts training for discipline and physical development.
                  </p>
                </div>
              </div>

              <div className="slider">
                <div className="carousel-item center" data-bs-interval="4000">
                  <h5>CODING, ROBOTICS, AND FINE ARTS</h5>
                  <p>Available through partnerships with private and public schools.</p>
                </div>
              </div>

              <div className="slider">
                <div className="carousel-item mid-right" data-bs-interval="4000">
                  <h5>ANNUAL FESTIVAL</h5>
                  <p>
                  The purpose of the event is to identify, develop, and nurture talent
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* content */}
          <div className="welcome">
            <h1>Welcome to The Hymnlets Dance & Art Academy</h1>
            <p>Unlock your child's artistic potential with expert training in <span className="typing">{typeEffect}</span></p>
            <Link to="#contact"><button className='learn-more'>GET IN TOUCH</button></Link>
          </div>
           
        </div>

        {/* About */}
        <div className="about" id="about">
          <AuroraBackground>
            <div className="about-cover">
              <h1 data-aos="slide-right">ABOUT HYMNLETS</h1>
              <div className="row" id="numerics">
                <div className="col" data-aos="fade-up">
                  <h2><CountUp end={1000} duration={5}/>+</h2>
                  <p>Trainees</p>
                </div>
                <div className="col" data-aos="fade-down">
                  <h2><CountUp end={40} duration={5}/>+</h2>
                  <p>Institutions</p>
                </div>
                <div className="col" data-aos="fade-left">
                  <h2><CountUp end={2} duration={5}/>+</h2>
                  <p>Festivals</p>
                </div>
                <div className="col" data-aos="fade-right">
                  <h2><CountUp end={2000} duration={5}/>+</h2>
                  <p>Festival Attendees</p>
                </div>
              </div>
              <div className="row" id="about-desc">
                <div className="col">
                  <img src={kendi} alt="Dance" />
                </div>
                <div className="col" data-aos="flip-left">
                  <p>
                    Located in Embakasi, Nairobi, Hymnlets Dance & Art Academy is a premier Performing Art Center dedicated to developing, nurturing, and managing talents in dance, music, and art. We offer tailored programs for people from 3 years and above, helping them explore their creativity, build confidence, and grow as performers.</p>

                  <p>At Hymnlets, we provide a supportive and fun environment where dancers, singers, and artists can thrive under the guidance of experienced instructors. Our programs are designed to inspire passion while ensuring that everyone receives personalized attention.
                  </p>
                    <Link to="/history"><button className='learn-more'>LEARN MORE</button></Link>
                </div>
              </div>
            </div>
          </AuroraBackground>
        </div>

        {/* Dance festival */}
        <div className="dance" id="dance">
              <h1>Dance Festival</h1>
              <div className="row">
                <div className="col">
                  <p>Hymnlets Dance and Arts Academy organizes annual dance festivals for children  and adults.</p>
                  <p>The event aims to identify, develop, and nurture talent and create unity and equality within our society. Equally, this event helps boost self-esteem, confidence and enhances presentation skills.</p>
                  <p>We have held successful dance editions which attracted 2000+ learners from different schools in and out of Nairobi, parents/guardians, teachers, and other dance academies.</p>
                </div>
                <div className="col" id="vid">
                  <video autoPlay loop muted playsInline className="dance-vid">
                    <source src={trainers} type="video/mp4" />
                  </video>
                </div>
              </div>
        </div>

        {/* Programs */}
        <div className="programs" id="programs">
          <h1>OUR PROGRAMS</h1>
          <div className="row">
            <InfiniteMovingCards
              items={items.map(item => ({ name: item.name, content: item.content }))}
              direction="left"  // Adjust the direction as per your preference
              speed="slow"  // Adjust the speed if needed
              pauseOnHover={true}  // Hover pauses the scroll
            />
          </div>
        </div>

        {/* Success */}
        <div className="success" id="success">
          <h1>OUR SUCCESS STORIES</h1>
          <div className="row">
            <div className="col"></div>
            <div className="col" id="stories-col">
            <h3>From Kenya to the World</h3>
              <p>
              Hymnlets Dance Academy has expanded beyond Kenya, with successful trips to Germany and upcoming visits to South Africa, Belgium, and Spain in 2025. Backed by organizations like Standard Group (KTN) and SHOFCO, our international impact continues to grow.
              </p>
            </div>
          </div>
          {/* Exchange Program */}
          <div className="row">
            <div className="col" id="exchange">
              <h3>Gymnastics Exchange Program in Germany</h3>
              <p>
              Our Gymnastics Exchange Program takes young athletes to Germany every two years, offering expert coaching, cultural exchange, and opportunities to compete and form lifelong friendships.              </p>
              <Link to="/success-stories"><button className='learn-more'>LEARN MORE</button></Link>
            </div>
            <div className="col">
            </div>
          </div>
          {/* Dance fest */}
          <div className="row">
            <div className="col"></div>
            <div className="col" id="stories-col">
            <h3>Annual Hymnlets Dance Festival</h3>
              <p>
              Our Annual Kids Dance Festival showcases the talents of young dancers in a celebration of creativity, teamwork, and self-expression through a variety of dance styl              </p>
              <Link to="/festival"><button className='learn-more'>LEARN MORE</button></Link>
            </div>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 240"><path fill="#FFC0CB" fill-opacity="1" d="M0,128L30,149.3C60,171,120,213,180,229.3C240,245,300,235,360,202.7C420,171,480,117,540,122.7C600,128,660,192,720,192C780,192,840,128,900,112C960,96,1020,128,1080,149.3C1140,171,1200,181,1260,176C1320,171,1380,149,1410,138.7L1440,128L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path></svg>
        </div>

        {/* Contact Us */}
        <div className="contact" id="contact">
          <div className="contact-cover">
            <div className="top-cover">
              <h1>Contact Us</h1>
              <p>
              We're looking forward to welcoming you to the Hymnlets family. <br /> Get in touch with the contact details below, or fill out the form below and we'll get back to you.
              </p>
              <div className="contact-info">
                <div className="row">
                  <div className="contact-col">
                    <div className="contact-inner-col" data-aos="flip-left">
                      <h1>Talk to us:</h1>
                      {/* phone */}
                      <div className="content">
                        <div className="icon">
                          <i class="bi bi-telephone-fill"></i>
                        </div>
                        <div className="info">
                          <ul>
                            <li>+254 711 453268</li>
                            <li>+254 738 948 486</li>
                          </ul>
                        </div>
                      </div>

                      {/* email */}
                      <div className="content">
                        <div className="icon">
                          <i class="bi bi-envelope-fill"></i>
                        </div>
                        <div className="info">
                          <ul>
                            <li>admin@hymnletsdanceacademy.co.ke</li>
                            <li>info@hymnletsdanceacademy.co.ke</li>
                            <li>cate@hymnletsdanceacademy.co.ke</li>
                          </ul>
                        </div>
                      </div>


                      {/* phone */}
                      <div className="content">
                        <div className="icon">
                          <i class="bi bi-geo-alt-fill"></i>
                        </div>
                        <div className="info">
                          <p>
                            Tassia School, Embakasi
                          </p>
                        </div>
                      </div>
                      <div className="content">
                        <div className="icon">
                          <i class="bi bi-mailbox"></i>
                        </div>
                        <div className="info">
                          <p>
                            Postal address: <br />
                            P.O. Box 35235-00200 <br /> Nairobi-Kenya

                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="contact-col">

                    <form>
                      <div className="form">
                        <label htmlFor="name">Your Name:</label>
                        <input type="text" name="name" id="name" placeholder='John Doe' /><br />
                      </div>
                      
                      <div className="form">
                        <label htmlFor="email">Your Email:</label>
                        <input type="email" name="email" id="email" placeholder='johndoe@example.com' /><br />
                      </div>

                      <div className="form">
                        <label htmlFor="subject">Subject:</label>
                        <input type="text" name="subject" id="subject" placeholder='E.g. How to join Hymnlets' /><br />
                      </div>

                      <div className="form">
                        <label htmlFor="message">Booking/Inquiry:</label>
                        <textarea name="message" id="message" cols="10" rows="5" placeholder='Send an inquiry or comment'></textarea>
                      </div>

                      <div className="form">
                        <button type="submit" id='btn'>Send Message</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="botton-cover">
            </div>
          </div>
        </div>
        {/* footer */}
        <div class="footer">
          <p>All rights reserved | <b>Hymnlets Dance & Arts Academy © 2024</b></p>
        </div>
        {/* cover end */}
      </div>
    </div>
  );
}

export default Home;
