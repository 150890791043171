import React from 'react';
import { Link } from 'react-router-dom';
import './festival.css';
import festival from '../../images/stage-ballet.jpg';

function Festival() {
  return (
    <div className="festival">
        <div className='festival-home'>
            <h1>ANNUAL DANCE FESTIVAL</h1>
            <div className="festival-desc">
                <p>Hymnlets Annual Dance Festival is a lively event that brings together talented dancers. This festival is a celebration of creativity, teamwork, and the pure joy of dance. Participants have the opportunity to showcase their skills in different dance styles while working in teams and solo performances.  </p>
            </div>
            <div className="festival-right-desc">
                <p>The festival not only highlights the artistic talents of these individuals but also fosters a sense of community and collaboration. It encourages self-expression, boosts confidence, and provides a platform to explore the beauty of movement and rhythm in a supportive, fun-filled environment.</p>
            </div>
        </div>

        <div className="festival-about">
            <div className="row">
                <div className="col">
                    <h1>Event Highlights</h1>
                    <ul>
                        <li>
                            <h4>Diverse Performances</h4>
                            <p>Enjoy captivating performances showcasing a variety of dance styles, from ballet to hip-hop.</p>
                        </li>
                        <li>
                            <h4>Community Spirit</h4>
                            <p>Foster friendships and camaraderie among dancers as they share their passion for movement and expression.</p>
                        </li>
                        <li>
                            <h4>Expert Judges</h4>
                            <p>Our festival features experienced judges who will provide constructive feedback and recognize outstanding performances.</p>
                        </li>
                        <li>
                            <h4>Fun and Learning</h4>
                            <p>Beyond the stage, participants can enjoy workshops and interactive sessions to enhance their skills and learn new techniques.</p>
                        </li>
                    </ul>
                    <Link to="/"><button className='learn-more'>Back Home</button></Link>
                </div>
                <div className="col">
                    <img src={festival} alt="festival" />
                </div>
            </div>

        </div>
    </div>
  )
}

export default Festival;
