import React from 'react';
import { Link } from 'react-router-dom';
import './gymnastics.css'

function Gymnastics() {
  return (
    <div className="gymnastics">
        <div className='gymnastics-home'>
            <h1>Gymnastics & Circus</h1>
            <div className="gymnastics-desc">
                <p>Offering both recreational and professional training through a partnership with a German-based company. At Hymnlets Academy we provide a comprehensive range of training programs that cater to both recreational enthusiasts and aspiring professional athletes.   </p>
                <Link to="/"><button className='learn-more'>Back Home</button></Link>
            </div>
            <div className="gymnastics-right-desc">
                <p>This is made possible through a collaboration with a renowned German-based company, known for its expertise in gymnastics coaching, cutting-edge techniques, and state-of-the-art equipment. The partnership ensures access to world-class coaching, fostering skill development from beginner levels to advanced competitive training, tailored to kids' individual needs and goals.</p>
            </div>
        </div>
    </div>
  )
}

export default Gymnastics;
