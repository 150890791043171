import React from 'react';
import { Link } from 'react-router-dom';
import './ballet.css'
import ballet from '../../images/ballet-about.jpg'

function Ballet() {
  return (
    <div className="ballet">
        <div className='ballet-home'>
            <h1>BALLET</h1>
            <div className="ballet-desc">
                <p>At Hymnlets Dance Academy, <b>ballet</b> forms the core of our programs. This classical dance not only provides a strong foundation in technique but also fosters confidence and creativity in young dancers. </p>
            </div>
            <div className="ballet-right-desc">
                <p>Ballet classes are cherished for their beauty and storytelling, and the Academy continuously localizes and innovates the experience to inspire our students.  </p>
            </div>
        </div>

        <div className="ballet-about">
            <div className="row">
                <div className="col">
                    <h1>Ballet: Grace, Discipline, and Creativity.</h1>
                    <p>
                    Ballet remains the centerpiece of our annual festivals, showcasing the grace and skill our children develop. <br />

                    Through ballet, children gain numerous physical and mental benefits. It enhances strength, flexibility, and coordination while promoting discipline, focus, and emotional expression. Ballet builds confidence as children master challenging moves and perform before an audience. The collaborative nature of classes fosters social skills, teamwork, and cultural appreciation, helping children develop a love for the arts and a healthy, active lifestyle.
                    <Link to="/"><button className='learn-more'>Back Home</button></Link>
                    </p>
                </div>
                <div className="col">
                    <img src={ballet} alt="Ballet" />
                </div>
            </div>

        </div>
    </div>
  )
}

export default Ballet;
