import './services.css';

function Sales({closeChild}) {
  return (
      <div className= 'leadership'>
        <i class="bi bi-x-square-fill" onClick={closeChild}></i> 
        <h3>Anonymous Expression Platforms</h3>
        <p> A digital "Unspoken Wall" for individuals to
anonymously share struggles, offering connection
and validation</p>
      </div>
  )
}

export default Sales;