import './services.css';

function Team({closeChild}) {
  return (
      <div className= 'leadership'>
        <i class="bi bi-x-square-fill" onClick={closeChild}></i> 
        <h3>Peer Support
        Networks</h3>
        <p>Peer-led groups offer ongoing
connections and support for
individuals on their mental health
journeys.</p>
      </div>
  )
}

export default Team;