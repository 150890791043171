import React from "react";
import { Link } from "react-router-dom";
import "./corevalues.css";
import wf from "../../images/about-img.jpg";

function Corevalues() {
  return (
    <div className="corevalues">
      <div className="services-top">
        <img src={wf} alt="Team building" />
      </div>
      <h1>Core Values</h1>
      <Link to="/"><button className='learn-more'>Back Home</button></Link>
      <div className="services-row">
        <div className="services-col">
          <h2>Teamwork</h2>
          <p>Encouraging collaboration and cooperation in every activity.{" "}</p>
        </div>
        <div className="services-col">
          <h2>Inclusivity</h2>
          <p>Welcoming and supporting children from diverse backgrounds.</p>
        </div>
        <div className="services-col">
          <h2>Creativity</h2>
          <p>Inspiring imagination and artistic expression.</p>
        </div>
        <div className="services-col">
          <h2>Discipline</h2>
          <p>Instilling focus and commitment in both learning and performance.</p>
        </div>
        <div className="services-col">
          <h2>Confidence</h2>
          <p>Building self-assurance through skill mastery.</p>
        </div>
        <div className="services-col">
          <h2>Resilience</h2>
          <p>Cultivating perseverance in the face of challenges.</p>
        </div>
        <div className="services-col">
          <h2>Cultural Appreciation</h2>
          <p>Promoting respect and understanding of different cultures through dance and art.</p>
        </div>
      </div>
    </div>
  );
}

export default Corevalues;
