import './services.css';

function Strategic({closeChild}) {
  return (
      <div className= 'leadership'>
        <i class="bi bi-x-square-fill" onClick={closeChild}></i> 
        <h3>Expressive Dance
Workshops
(Alternative Therapy)</h3>
        <p>Guided sessions using movement to explore
and express unspoken emotions, led by
professional dance therapists and mental
health experts.</p>
      </div>
  )
}

export default Strategic;