import React from 'react';
import { useTypewriter } from 'react-simple-typewriter';
import { Link } from 'react-router-dom';
import './success.css';
import dance from '../../images/dancing-kids.jpg'

function Success() {
  const [typeEffect] = useTypewriter({
    words: ['Train With Expert Coaches', 'Make Lifelong Friends', 'Participate in Local Competitions', 'Experience Another Culture'],
    loop: {},
    typeSpeed: 100,
    deleteSpeed: 40,
    delaySpeed: 2000
  })
  return (
    <div className='success-stories'>
      <div className="success-top">
        <div className="success-top-cover">
          <h1>Gymnastics Exchange Program in Germany</h1>
          <p>The experience allows young athletes to <span className="typing">{typeEffect}</span></p>
        </div>
      </div>
      <div className="bottom-cover">
        <h2>Gymnastics Exchange Program</h2>
        <div className="success-desc">
          <div className="row">
            <div className="col">
              <p>
              Experience the thrill of gymnastics like never before! Our exclusive Gymnastics Exchange Program offers children the incredible opportunity to travel to Germany for a full month every two years. 
              </p>
              <ul>
              This immersive experience allows young athletes to:
                <li><b>Train with Expert Coaches:</b> Gain valuable skills and techniques from some of the best gymnastics coaches in Germany.</li>
                <li><b>Cultural Exchange:</b> Immerse yourself in German culture, enhancing both personal growth and athletic development.</li>
                <li><b>Make Lifelong Friends:</b> Connect with fellow gymnasts from around the world, sharing experiences and building friendships that last a lifetime.</li>
                <li><b>Participate in Local Competitions:</b> Showcase your skills in friendly competitions, gaining confidence and experience in a supportive environment.</li>
              </ul>
              <Link to="/"><button className='learn-more'>Back Home</button></Link>
            </div>
            <div className="col">
              <img src={dance} alt="Kids on stage" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Success;