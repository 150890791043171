import './services.css';

function Leadership({closeChild}) {
  return (
      <div className= 'leadership'>
        <i class="bi bi-x-square-fill" onClick={closeChild}></i> 
        <h3>Storytelling Circles</h3>
        <p>A safe, guided space for
participants to share personal stories with
openness and mutual support</p>
      </div>
  )
}

export default Leadership;