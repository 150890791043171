import './services.css';

function Supervisory({closeChild}) {
  return (
      <div className= 'leadership'>
        <i class="bi bi-x-square-fill" onClick={closeChild}></i> 
        <h3>Creative Safe
        Spaces</h3>
        <p>Community hubs for individuals to express
emotions through dance, music, poetry,
and visual arts, offering ongoing mental
health support.</p>
      </div>
  )
}

export default Supervisory;