import React from 'react';
import './karate.css';
import { Link } from 'react-router-dom';
import karate from '../../images/karate-kid.png'

function Karate() {
    // const navigate = useNavigate();
    // const handleNavigate = () => {
    //   navigate('/');
    // }
  return (
    <div className='training'>
            <div className="training-cover">
              <h1>Karate and Taekwondo – Strength and Discipline</h1>
              {/* data-aos="slide-right" */}
              <div className="row">
                <div className="col">
                  <img src={karate} alt="Dance" />
                </div>
                <div className="col">
                {/* data-aos="flip-left" */}
                  <p>
                  At Hymnlets Dance & Arts Academy, Karate and Taekwondo offer children a blend of physical development and personal discipline. Karate, from Japan, focuses on self-defense through powerful strikes and strong stances, improving strength, balance, and coordination. It also instills respect, self-control, and focus in students. </p>

                  <p>Taekwondo, originating from Korea, is known for its dynamic kicks and fast movements, enhancing flexibility and fitness. Both martial arts promote discipline, perseverance, and respect, fostering personal growth alongside physical strength and boosting confidence.
                  </p>

              <Link to="/"><button className='learn-more'>Back Home</button></Link>
                </div>
              </div>
            </div>
    </div>
  )
}

export default Karate;