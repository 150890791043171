import React from 'react'
import Home from '../home/Home';
import { Route, Routes } from 'react-router-dom';
import History from '../history/History';
import Ballet from '../ballet/Ballet';
import Modern from '../modern/Modern';
import Gymnastics from '../gymnastics/Gymnastics';
import Coding from '../coding/Coding';
import Unspoken from '../unspoken/Unspoken';
import Festival from '../festival/Festival';
import Karate from '../karate/Karate';
import Success from '../success/Success';
import NotFound from '../notFound/NotFound';
import Diversity from '../diversity/Diversity';
import ScrollToTop from '../scrollToTop';
import Corevalues from '../corevalues/Corevalues';
import Gallery from '../gallery/Gallery';
import Awards from '../awards/Awards';

function Main() {
  return (
    <div>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/history' element={<History />} />
        <Route path='/ballet' element={ <Ballet />}  />
        <Route path='/modern' element={ <Modern />}  />
        <Route path='/gymnastics' element={ <Gymnastics />}  />
        <Route path='/coding-robotics-fine-arts' element={ <Coding />}  />
        <Route path='/the-unspoken' element={ <Unspoken />}  />
        <Route path='/festival' element={ <Festival />}  />
        <Route path='/karate' element={ <Karate />}  />
        <Route path='/success-stories' element={ <Success />}  />
        <Route path='/diversity-and-inclusion' element={ <Diversity />}  />
        <Route path='/core-values' element={ <Corevalues />}  />
        <Route path='/gallery' element={ <Gallery />}  />
        <Route path='/awards' element={ <Awards />}  />
        <Route path='*' element={ <NotFound />}  />
      </Routes>  
    </div>
  )
}

export default Main;