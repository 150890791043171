import React from 'react';
import { Link } from 'react-router-dom';
import './history.css';

function History() {
  return (
    <div className='history'>
        <h1>WHO ARE WE</h1>
        
        <div className="history-cover">
            <p>
            Founded in 2015, Hymnlets Dance Academy began as a simple idea to engage children in a gated community. The academy's founder Ms. Karimi, noticing a growing number of children playing outside, decided to organize activities to keep them creatively engaged. What started as small Saturday gatherings where kids sang and played music soon blossomed into a structured dance program.
            </p>
            <p>
            The academy quickly gained popularity, attracting children aged 3 and above, and expanding its offerings. With the help of a former student, Hymnlets introduced ballet classes, and over time, the academy began to feature additional dance styles such as modern dance, salsa, and more. As its reputation grew, Hymnlets secured partnerships with local schools and opened a studio in Nyayo Estate.
            </p>
            <div className="row">
                    <h3 className="title">OUR VISION</h3>
                    <p>Empowering express, and children excel to discover, through the transformative power of dance and arts.</p>
                                
                <div className="row">
                    <h3 className="title">OUR MISSION</h3>
                    <p>To inspire and nurture children’s creativity and self-expression through exceptional dance and art education.</p>
                </div>
                
                <div className="row">
                    <h3 className="title">CORE VALUES</h3>
                    <p>At Hymnlets Dance & Arts Academy, we are committed to fostering values that align with the Competency-Based Curriculum (CBC). 
                    <Link to="/core-values"><button className='learn-more'>Read More</button></Link></p>
                    <Link to="/"><button className='learn-more'>Back Home</button></Link>
                </div>
            </div>
        </div>
    </div>
  )
}

export default History;