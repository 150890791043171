import './services.css';

function Financial({closeChild}) {
  return (
      <div className= 'leadership'>
        <i class="bi bi-x-square-fill" onClick={closeChild}></i> 
        <h3>Dance Production
        Performances</h3>
        <p> "The Unspoken" will feature live dance
productions portraying mental health
struggles, using dance to create deep
emotional connections and draw large
audiences.</p>
      </div>
  )
}

export default Financial;