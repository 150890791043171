import './services.css';

function Customer({closeChild}) {
  return (
      <div className= 'leadership'>
        <i class="bi bi-x-square-fill" onClick={closeChild}></i> 
        <h3>Panel Discussions and Workshops</h3>
        <p>Expert-led sessions offering tools and
resources on mental health, emotional
regulation, and resilience-building.</p>
      </div>
  )
}

export default Customer;