import './services.css';

function Business({closeChild}) {
  return (
      <div className= 'leadership'>
        <i class="bi bi-x-square-fill" onClick={closeChild}></i> 
        <h3>Healing
Through
Movement
Sessions</h3>
        <p>Interactive post-performance sessions
where participants engage in guided
dance therapy exercises to experience the
healing power of movement.</p>
      </div>
  )
}

export default Business;