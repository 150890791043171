import React from 'react'
import { Link } from 'react-router-dom';
import './modern.css';

function Modern() {
  return (
    <div className="modern">
        <div className='modern-home'>
            <h1>MODERN DANCE/FUSION</h1>
            <div className="modern-desc">
                <p>Energetic and dynamic, this category appeals to kids who love high-energy movement. This dance style is perfect for kids who are full of energy and enjoy expressing themselves through fast-paced, dynamic movement. It combines elements of modern dance with various other genres like hip-hop, jazz, or even contemporary styles. Modern Dance/Fusion allows for a wide range of creative expression, where dancers can use the entire body in powerful, fluid motions.  </p>
            </div>
            <div className="modern-right-desc">
                <p>It emphasizes freedom of movement, improvisation, and often includes bold, dramatic choreography. At Hymnlets Dance Academy, kids who love jumping, spinning, and constantly moving will find this style exciting, as it often involves upbeat music and highly expressive, theatrical performances. It encourages individuality and creativity, making it great for kids who enjoy pushing the boundaries of traditional dance styles  </p>
                <Link to="/"><button className='learn-more'>Back Home</button></Link>
            </div>
        </div>
    </div>
  )
}

export default Modern;
