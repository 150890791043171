import './services.css';

function MSME({closeChild}) {
  return (
      <div className= 'leadership'>
        <i class="bi bi-x-square-fill" onClick={closeChild}></i> 
        <h3>Mental Health Advocacy and Awareness Campaigns</h3>
        <p>A public campaign using storytelling and creative
expressions to raise awareness and break the stigma
around mental health.</p>
      </div>
  )
}

export default MSME;