import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './unspoken.css';
import Leadership from './Leadership';
import Customer from './Customer';
import Sales from './Sales';
import MSME from './MSME';
import Team from './Team';
import Strategic from './Strategic';
import Financial from './Financial';
import Business from './Business';
import Supervisory from './Supervisory';
import Inteligence from './Inteligence';
import Risk from './Risk';
import unspoken from '../../images/unspoken-depressed.jpg';

function Unspoken() {
  const [activeComponent, setActiveComponent] = useState(null);
  const renderComponent = () => {
    switch (activeComponent) {
      case 'Leadership':
        return <Leadership closeChild={() => setActiveComponent(null)} />;
      case 'Customer':
        return <Customer closeChild={() => setActiveComponent(null)} />;
      case 'Sales':
        return <Sales closeChild={() => setActiveComponent(null)} />;
      case 'MSME':
        return <MSME closeChild={() => setActiveComponent(null)} />;
      case 'Team':
        return <Team closeChild={() => setActiveComponent(null)} />;
      case 'Strategic':
        return <Strategic closeChild={() => setActiveComponent(null)} />;
      case 'Financial':
        return <Financial closeChild={() => setActiveComponent(null)} />;
      case 'Business':
        return <Business closeChild={() => setActiveComponent(null)} />;
      case 'Supervisory':
        return <Supervisory closeChild={() => setActiveComponent(null)} />;
      case 'Inteligence':
        return <Inteligence closeChild={() => setActiveComponent(null)} />;
      case 'Risk':
        return <Risk closeChild={() => setActiveComponent(null)} />;
      default:
        return null;
    }
  };
  return (
    <div className='unspoken'>
      <div className="unspoken-content">
        <h1>The Unspoken</h1>
          <h6>Mental health awareness</h6>
        <div className="unspoken-desc">
          <div className="unspoken-into">
            <p>
              The Unspoken Dance Program is a transformative initiative under Hymnlets Dance Academy, designed to raise awareness about mental health through the power of expressive dance. The term "Unspoken" symbolizes the unaddressed issues in our society—issues that weigh heavily but are often left unsaid. These issues, when ignored, lead to devastating consequences like suicide, violence, and behaviors rooted in hate, narcissism, and rejection.
            </p>
            <p>
              Through this program, we use the art of dance to give a voice to these "unspoken" struggles, allowing individuals to express complex emotions such as anxiety, fear, sadness, and depression. In collaboration with counseling centers and therapists, we provide an alternative form of therapy, making dance a safe and expressive medium for mental health advocacy.
            </p>
          </div>
          <div className="unspoken-image">
            <img src={unspoken} alt="" />
          </div>
        </div>
          <Link to="/"><button className='learn-more'>Back Home</button></Link>
        <div className="unspoken-element">
          <h1>Program Elements:</h1>
          <div className="services-row">
          <div className="services-col" onClick={() => setActiveComponent('Leadership')}>
            <p>Storytelling Circles</p>
          </div>
          <div className="services-col" onClick={() => setActiveComponent('Customer')}>
            <p>Panel Discussions and Workshops</p>
          </div>
          <div className="services-col" onClick={() => setActiveComponent('Sales')}>
            <p>Anonymous Expression Platforms</p>
          </div>
          <div className="services-col" onClick={() => setActiveComponent('MSME')}>
            <p>Mental Health Advocacy and Awareness Campaigns</p>
          </div>
          <div className="services-col" onClick={() => setActiveComponent('Team')}>
            <p>Peer Support Networks</p>
          </div>
        </div>

        <div className="services-row">
          <div className="services-col" onClick={() => setActiveComponent('Strategic')}>
            <p>Expressive Dance Workshops (Alternative Therapy)</p>
          </div>
          <div className="services-col" onClick={() => setActiveComponent('Financial')}>
            <p>Dance Production Performances</p>
          </div>
          <div className="services-col" onClick={() => setActiveComponent('Business')}>
            <p>Healing Through Movement Sessions</p>
          </div>
          <div className="services-col" onClick={() => setActiveComponent('Supervisory')}>
            <p>Creative Safe Spaces</p>
          </div>
        </div>

        {/* Render the active component */}
        {renderComponent()}
          <div className="elements">
            <div className="element-content"></div>
            <div className="element-image"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Unspoken;
